import React from 'react'
import ProductCard from '../layouts/Product-Card'
import { productsMain } from '../db'
import PrimarySearchAppBar from '../layouts/Navbar'
import { Helmet }from 'react-helmet'

const ProductScreen = () => {
    return (
        <>
            <PrimarySearchAppBar page="products"/>
            <Helmet>
                <title>Brightech Industries</title>
            </Helmet>
            <div className="container-fluid container-catalogue mt-5">
                <div className="container content">
                    <h1 className="text-reveal color-white text-center mb-4 center">
                        Our Products Range
                    </h1>
                    {/* <div className="row row-cols-1 row-cols-lg-3 g-2">
                        {productsMain.map(product => (
                            <ProductCard key={product._id} id={product._id} name={product.name} desc={product.desc} img={product.img}/>
                        ))}
                    </div> */}
                    <div className="row rowProducts">
                        {productsMain.map(product => (
                            <ProductCard key={product._id} id={product._id} name={product.name} desc={product.desc} img={product.img}/>
                        ))}
                    </div>
                </div>
            </div>   
        </>
    )
}

export default ProductScreen
