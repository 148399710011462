import React from 'react'
import Ceramics from '../assets/Ceramics-1.webp'
import Cosmetics from '../assets/Cosmetics-1.webp'
import Bricks from '../assets/Fire_Bricks-1.webp'
import Insecticides from '../assets/Insecticides___Pesticides.webp'
import Paint from '../assets/Paint-1.webp'
import Paper from '../assets/Paper_Coating.webp'
import Pharmaceutical from '../assets/Pharmaceutical-1.webp'
import PVC from '../assets/PVC.webp'
import Rubber from '../assets/Rubber-1.webp'
import Detergent from '../assets/Detergent_Powder.webp'

const Slider = () => {
    return (
        <div id="carouselExampleInterval" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner">
            <div className="carousel-item active" data-bs-interval="4000">
                <img height="100%" width="100%" src={Ceramics} className="slider-img d-block w-100" alt="..."/>
                <div className="image-text text-uppercase"><span className="color-bg">Ceramics</span></div>
            </div>
            <div className="carousel-item" data-bs-interval="4000">
                <img height="100%" width="100%" src={Rubber} className="slider-img d-block w-100" alt="..."/>
                <div className="image-text text-uppercase"><span className="color-bg">Rubber</span></div>
            </div>
            <div className="carousel-item" data-bs-interval="4000">
                <img height="100%" width="100%" src={Bricks} className="slider-img d-block w-100" alt="..."/>
                <div className="image-text text-uppercase"><span className="color-bg">Fire Bricks</span></div>
            </div>
            <div className="carousel-item" data-bs-interval="4000">
                <img height="100%" width="100%" src={Cosmetics} className="slider-img d-block w-100" alt="..."/>
                <div className="image-text text-uppercase"><span className="color-bg">Cosmetics</span></div>
            </div>
            <div className="carousel-item" data-bs-interval="4000">
                <img height="100%" width="100%" src={Insecticides} className="slider-img d-block w-100" alt="..."/>
                <div className="image-text text-uppercase"><span className="color-bg">Pesticides &amp; Insecticides</span></div>
            </div>
            <div className="carousel-item" data-bs-interval="4000">
                <img height="100%" width="100%" src={Paint} className="slider-img d-block w-100" alt="..."/>
                <div className="image-text text-uppercase"><span className="color-bg">Paint</span></div>
            </div>
            <div className="carousel-item" data-bs-interval="4000">
                <img height="100%" width="100%" src={Paper} className="slider-img d-block w-100" alt="..."/>
                <div className="image-text text-uppercase"><span className="color-bg">Paper Coating</span></div>
            </div>
            <div className="carousel-item" data-bs-interval="4000">
                <img height="100%" width="100%" src={Detergent} className="slider-img d-block w-100" alt="..."/>
                <div className="image-text text-uppercase"><span className="color-bg">Detergent Powder &amp; Soap</span></div>
            </div>
            <div className="carousel-item" data-bs-interval="4000">
                <img height="100%" width="100%" src={Pharmaceutical} className="slider-img d-block w-100" alt="..."/>
                <div className="image-text text-uppercase"><span className="color-bg">Pharmaceutical</span></div>
            </div>
            <div className="carousel-item" data-bs-interval="4000">
                <img height="100%" width="100%" src={PVC} className="slider-img d-block w-100" alt="..."/>
                <div className="image-text text-uppercase"><span className="color-bg">PVC</span></div>
            </div>
        </div>
        <a className="carousel-control-prev" href="#carouselExampleInterval" role="button" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
        </a>
        <a className="carousel-control-next" href="#carouselExampleInterval" role="button" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
        </a>
        </div>
    )
}

export default Slider
