import React from 'react'
import Navbar from '../layouts/Navbar'
import Footer from '../layouts/Footer'

const Page404 = () => {
    return (
        <>
            <Navbar />
            <div className="P404">
                <div className="center">
                    <a href="/"><button className="btn btn-block mx-auto btn-orange bg-prime">Return to Home</button></a>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default Page404
