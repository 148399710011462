import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import PrimarySearchAppBar from '../layouts/Navbar'
import { productsMain } from '../db'

const SingleProductScreen = () => {
    const id = window.location.pathname.split("products/")[1];
    const [product, setProduct] = useState({name: "Kaustubh", desc: "Kaustubh", img: "Kaustubh", applications: ["hello", "Hi"]});
    useEffect(() => {
        const product2 = (productsMain.filter(product => product.name.split(' ')[0] === id))
        setProduct(product2[0])
        console.log(id);
    // eslint-disable-next-line
    }, [id])

    const { name, desc, img, applications } = product;

    return (
        <>
            <PrimarySearchAppBar page="products"/>
            <div className="pt-5">
                <Helmet>
                    <title className="color-white">{`Brightech | ${name}`}</title>
                </Helmet>
                <div className="container mt-5 pt-5 center justify-content-center">
                    <div className="center mt-5 row ">
                        <img height="500px" width="100%" className="center col-12 offset-lg-2 col-lg-8" loading="lazy" src={img} alt={name}/>
                    </div>
                    <h1 className="text-reveal color-white text-center mt-5 center">
                        <ins>{name}</ins>
                    </h1>
                    <div className="content col-12 col-lg-8 offset-lg-2 p-3 text-center">
                        <p className="lead color-white text-center">{ desc }</p>
                        <p className="color-white text-center mx-auto">{`Applications of ${name}  are:`}</p><br />
                        <ul style={{marginTop: '-15px'}} className="color-white mb-5">
                        {
                            applications.map((app) => (
                                <li className="my-2 applications">{app}</li>
                            ))
                        }
                        </ul>
                        <a className="color-white go-home text-center mb-5" href="/"><i className="fas fa-angle-left color-white"></i> Go Back Home</a><br/>
                        <a className="color-white go-home text-center mb-5" href="/products">Show more Products <i className="fas fa-angle-right color-white"></i> </a>
                    </div>
                </div>
            </div>
            {/* <PrimarySearchAppBar page="products"/>
            <div className="pt-5">
                <Helmet>
                    <title className="color-white">{`Brightech | ${name}`}</title>
                </Helmet>
                <div className="container mt-5 pt-5 center justify-content-center">
                    <div className="center mt-5 row ">
                        <img height="500px" width="100%" className="center col-12 offset-lg-2 col-lg-8" src={img} alt={name}/>
                    </div>
                    <h1 className="text-reveal color-white text-center mt-5 center">
                        <ins>{name}</ins>
                    </h1>
                    <div className="content col-12 col-lg-8 offset-lg-2 p-3 text-center">
                        <p className="lead color-white text-center mb-5">{ descLong }</p>
                        <a className="color-white go-home text-center mb-5" href="/"><i className="fas fa-angle-left color-white"></i> Go Back Home</a><br/>
                        <a className="color-white go-home text-center mb-5" href="/products">Show more Products <i className="fas fa-angle-right color-white"></i> </a>
                    </div>
                </div>
            </div> */}
        </>
    )
}

export default SingleProductScreen
