import BTGranule from '../src/assets/catalogue/BT-Granule-Silica.webp'
import BTSilicaP from '../src/assets/catalogue/BT-Silica-ppt-plus.webp'
import BTSilica from '../src/assets/catalogue/BT-Silica-ppt.webp'
import Calcite from '../src/assets/catalogue/Calcite.webp'
import Calcium from '../src/assets/catalogue/Calcium.webp'
import Carbon from '../src/assets/catalogue/Carbon.webp'
import China from '../src/assets/catalogue/China-Clay.webp'
import DEG from '../src/assets/catalogue/DEG.webp'
import Dolomite from '../src/assets/catalogue/Dolomite.webp'
import Kaolin from '../src/assets/catalogue/Kaolin-Clay.webp'
import Maize from '../src/assets/catalogue/Maize-Starch.webp'
import MEG from '../src/assets/catalogue/MEG.webp'
import RedO from '../src/assets/catalogue/Natural-Red-Oxide.webp'
import YellowO from '../src/assets/catalogue/Natural-Yellow-Oxide.webp'
import SnowWhite from '../src/assets/catalogue/Snow-White-Levigated-Clay.webp'
import SRedO from '../src/assets/catalogue/Synthetic-Red-Oxide.webp'
import SYellowO from '../src/assets/catalogue/Synthetic-Yellow-Oxide.webp'
import Talcum from '../src/assets/catalogue/Talcum.webp'

export const details = {
    "title": "Brightech Industries"
}
export const products = [
    {
        "_id": "10",
        "name": "Calcium",
        "img": Calcium,
        "desc": "We have various grades of Calcium for different Industrial use."
    },
    {
        "_id": "2",
        "name": "Kaolin Clay",
        "img": Kaolin,
        "desc": "We have various grades of Kaolin Clay for different Industrial use"
    },
    {
        "_id": "3",
        "name": "China Clay",
        "img": China,
        "desc": "We have various grades of China Clay for different Industrial use"
    },
    {
        "_id": "4",
        "name": "Talcum",
        "img": Talcum,
        "desc": "We have various grades of Talcum for different Industrial use"
    },
    {
        "_id": "5",
        "name": "Dolomite",
        "img": Dolomite,
        "desc": "We have various grades of Dolomite for different Industrial use."
    },
    {
        "_id": "6",
        "name": "Calcite",
        "img": Calcite,
        "desc": "We have various grades of Clacite for different Industrial use"
    }
]
export const productsMain = [
    {
        "_id": "1",
        "name": "Snow White Levigated Clay",
        "img": SnowWhite,
        "applications": ["Paints", "Rubber", "Pesticides & Insecticides", "Pharmaceutical", "	Detergent Powder & Soap"],
        "desc": "We have various grades of Kaolin Clay for different Industrial use"
    },
    {
        "_id": "2",
        "name": "Kaolin Clay",
        "img": Kaolin,
        "applications": ["Cosmetics", "Rubber", "Pesticides & Insecticides", "Pharmaceutical"],
        "desc": "We have various grades of Kaolin Clay for different Industrial use"
    },
    {
        "_id": "3",
        "name": "China Clay",
        "img": China,
        "applications": ["Paints", "Rubber", "Pesticides & Insecticides", "Detergent Powder & Soap"],
        "desc": "We have various grades of China Clay for different Industrial use"
    },
    {
        "_id": "4",
        "name": "Talcum",
        "img": Talcum,
        "applications": ["Cosmetics", "Paints", "PVC"],
        "desc": "We have various grades of Talcum for different Industrial use"
    },
    {
        "_id": "5",
        "name": "Dolomite",
        "img": Dolomite,
        "applications": ["Rubber", "Pesticides & Insecticides"],
        "desc": "We have various grades of Dolomite for different Industrial use."
    },
    {
        "_id": "6",
        "name": "Calcite",
        "img": Calcite,
        "applications": ["Rexine", "Rubber", "Paints", "PVC"],
        "desc": "We have various grades of Clacite for different Industrial use"
    },
    {
        "_id": "7",
        "name": "BT+ Silica (ppt. Silica Powder)",
        "img": BTSilicaP,
        "applications": ["Rubber", "Cosmetics", "Pesticides & Insecticides"],
        "desc": "This is our finest grade of Precipated Silica Powder which is used in different Industries.",
    },
    {
        "_id": "8",
        "name": "BT Silica (ppt Silica Powder)",
        "img": BTSilica,
        "applications": ["Rubber", "Cosmetics", "Pesticides & Insecticides"]
    },
    {
        "_id": "9",
        "name": "BT Granule Silica",
        "img": BTGranule,
        "applications": ["Rubber", "Cosmetics"],
    },
    {
        "_id": "10",
        "name": "Calcium",
        "img": Calcium,
        "applications": ["Pharmaceutical", "Paints", "Rubber", "Cosmetics"],
        "desc": "We have various grades of Calcium for different Industrial use.",
    },
    {
        "_id": "11",
        "name": "Natural Yellow Oxide",
        "img": YellowO,
        "applications": ["Paints", "Polish"]
    },
    {
        "_id": "12",
        "name": "Synthetic Yellow Oxide",
        "img": SYellowO,
        "applications": ["Cement Bricks", "Paints"]
    },
    {
        "_id": "13",
        "name": "Natural Red Oxide",
        "img": RedO,
        "applications": ["Paints", "Polish"]
    },
    {
        "_id": "14",
        "name": "Synthetic Red Oxide",
        "img": SRedO,
        "applications": ["Cement Bricks", "Paints"]
    },
    {
        "_id": "15",
        "name": "Carbon",
        "img": Carbon,
        "applications": ["Rubber", "Paints", "Polish"]
    },
    {
        "_id": "16",
        "name": "Maize Starch",
        "img": Maize,
        "applications": ["Paints", "Pesticide & Insecticide", "Pharmaceutical"]
    },
    {
        "_id": "17",
        "name": "MEG (Mono Ethylene Glycols)",
        "img": MEG,
        "applications": ["Pesticide & Insecticide", "Paints"]
    },
    {
        "_id": "18",
        "name": "DEG (Di Ethylene Glycols)",
        "img": DEG,
        "applications": ["Pesticide & Insecticide", "Paints"]
    },
]