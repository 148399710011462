import React from 'react'
import { Helmet } from 'react-helmet'
import PrimarySearchAppBar from '../layouts/Navbar'


const ContactScreen = () => {
    return (
        <>
            <PrimarySearchAppBar page="contact" />
            <div className="container-fluid container-catalogue">
                <Helmet>
                    <title>Brightech | Contact Us</title>
                </Helmet>
                <div className="container mt-4">
                    <div className=" row mt-5">
                        <div className="col-12 col-lg-4 d-none d-lg-block">
                            <div className="px-5 mt-4 bg-icon office">
                                <h5 className="content-orange">Brightech Industries</h5>
                                <p style={{marginTop: "-5px", fontSize: "0.9em", maxWidth: '215px'}} className="color-white"><img style={{marginBottom: '2px', marginLeft: '-19px'}} width="15px" alt="Arrow" src="https://img.icons8.com/ios/24/ff5e0e/chevron-right.png"/> Rania Industrial Area, <br/> Kanpur (Dehat), <br/> Uttar Pradesh (209304).</p>
                                <p style={{marginTop: "-5px", fontSize: "0.9em", maxWidth: '215px'}} className="color-white"><img style={{marginBottom: '2px', marginLeft: '-19px'}} width="15px" alt="Arrow" src="https://img.icons8.com/ios/24/ff5e0e/chevron-right.png"/> Kundli Industrial Area, <br/> Kundli (Sonepat), <br/> Haryana (131028)</p>
                            </div>
                            <hr />
                            <div className="px-5 mt-4 bg-icon home">
                                <h5 className="content-orange">Office Address</h5>
                                <p style={{marginTop: "-5px", fontSize: "0.9em", maxWidth: '215px'}} className="color-white"><img style={{marginBottom: '2px', marginLeft: '-19px'}} width="15px" alt="Arrow" src="https://img.icons8.com/ios/24/ff5e0e/chevron-right.png"/> Aggarwal Shoppers Plaza, <br/> Sector 16, Rohini, <br/> New Delhi (110085)</p>
                                <p style={{marginTop: "-5px", fontSize: "0.9em", maxWidth: '215px'}} className="color-white"><img style={{marginBottom: '2px', marginLeft: '-19px'}} width="15px" alt="Arrow" src="https://img.icons8.com/ios/24/ff5e0e/chevron-right.png"/> Kundli (Sonepat), <br/> Haryana (131028)</p>
                            </div>
                            <hr />
                            <div className="px-5 mt-4 bg-icon call">
                                <h5 className="content-orange">Call Us</h5>
                                <p style={{marginTop: "-5px", fontSize: "0.9em", maxWidth: '215px'}} className="color-white"><img style={{marginBottom: '2px', marginLeft: '-19px'}} width="15px" alt="Arrow" src="https://img.icons8.com/ios/24/ff5e0e/chevron-right.png"/> +91-8375041191</p>
                            </div>
                            <hr/>
                            <div className="px-5 mt-4 bg-icon email">
                                <h5 className="content-orange">Email</h5>
                                <p style={{marginTop: "-5px", fontSize: "0.9em", maxWidth: '215px'}} className="color-white"><img style={{marginBottom: '2px', marginLeft: '-19px'}} width="15px" alt="Arrow" src="https://img.icons8.com/ios/24/ff5e0e/chevron-right.png"/> info@brightechindustries.com</p>
                            </div>
                            <hr/>
                        </div>
                        <form className="col-12 col-lg-6 offset-lg-2 pe-5 mt-3" action="https://formspree.io/f/xleokrvl" method="POST">
                        <h1 className="text-reveal color-white text-center mb-4 center">
                            Contact Us
                        </h1>
                            <div class="row mt-1g-3">
                                <div class="col">
                                    <input type="text" name="name" class="form-control" placeholder="Your name" aria-label="Your name" required/>
                                </div>
                                <div class="col">
                                    <input type="text" name="email" class="form-control" placeholder="Your Email" aria-label="Email" required/>
                                </div>
                            </div>
                            <div class=" row mt-1 g-3">
                                <div class="col">
                                    <input type="text" name="phone" class="form-control" placeholder="Contact Number" aria-label="Contact Number" required/>
                                </div>
                                <div class="col">
                                    <input type="text"  name="city" class="form-control" placeholder="Your City" aria-label="Your City" required/>
                                </div>
                            </div>
                            <div class="mt-3">
                                <textarea class="form-control" name="message" id="message" rows="15" placeholder="Comments or Other Inqueries"></textarea>
                            </div>
                            <div className="d-grid gap-2 col-6 col-lg-4 col-lg-3 mx-auto">
                                <input type="submit" className="mt-3 btn btn-block btn-orange"/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactScreen
