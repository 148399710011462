import React from 'react'
import { Link } from 'react-router-dom'

const Catalogue = ({ name, desc, img, id }) => {
    return (
        <div className="col mt-2">
            <div className="card p-3">
                <Link to={`/products/${name.split(' ')[0]}`}><img className="product-image p-1 card-img-top" src={img} alt="product" /></Link>
                <Link to={`/products/${name.split(' ')[0]}`}><h2 style={{cursor: 'pointer'}} className="color-white mt-3">{name}</h2></Link>
                <p className="card-text color-white px-2 pt-1 pb-3">{desc}</p>
            </div>
        </div>
    )
}

export default Catalogue
