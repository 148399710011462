import React from 'react'
import './App.min.css'
import { Route, Switch, Redirect } from 'react-router-dom'
import HomeScreen from './pages/HomeScreen';
import ProductScreen from './pages/ProductScreen';
import Footer from './layouts/Footer';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import AboutScreen from './pages/AboutScreen';
import ContactScreen from './pages/ContactScreen';
import SingleProductScreen from './pages/SingleProductScreen';
import Page404 from './pages/404Page';

function App() {  
  const topFunction = () => {
      window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
      })
  }
  return (
      <div className="App">
      <button onClick={topFunction} id="myBtn"><KeyboardArrowUpIcon fontSize="large" /></button>
        <Switch>
          <Route exact path="/"><HomeScreen/></Route>
          <Route exact path="/about"><AboutScreen/></Route>
          <Route exact path="/contact"><ContactScreen/></Route>
          <Route exact path="/products"><ProductScreen/></Route>
          <Route exact path="/products/:name"><SingleProductScreen/></Route>
          <Route exact path="/noPage"><Page404/></Route>
          <Redirect to="/noPage" />
        </Switch>
        <Footer/>
      </div>
  );
}

export default App;
