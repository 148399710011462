import React from 'react'
import Logo from '../assets/logo-line.webp'
import { Link } from 'react-router-dom'

const Navbar = ({page}) => {
  return (
    <div className="container-fluid header-bg">
      <div className="container">
        <div className="row align-middle pt-3">
          <p className="text-start"><a style={{color: "#000"}} className="lead mx-2" href="https://www.facebook.com/official.BTI"><i class="fa fa-facebook-official"></i></a><a style={{color: "#000"}} className="lead mx-2" href="https://www.instagram.com/brightech_industries/"><i class="fa fa-instagram"></i></a><a style={{color: "#000"}} className="lead mx-2" href="https://wa.me/+918375041191"><i class="fab fa-whatsapp"></i></a><a style={{color: '#000'}} href="mailto:info@brightechindustries.com"><span className="text-end float-end">info@brightechindustries.com</span></a></p>
          <p className="color-white text-end"></p>
        </div>
        <div id="navbar" className="container appBar">
          <img alt="Brightech Industres" onClick={() => {window.location.href = "/"}} src={Logo} className="logo color-white p-3 my-auto"></img>
          <ul className="menu-link d-none d-lg-flex">
            <Link className="text-uppercase" style={{textDecoration: "none"}} to="/"><li className={page === 'home' ? "nav-item active" : "nav-item"}>Home</li></Link>
            <Link className="text-uppercase" style={{textDecoration: "none"}} to="/about"><li className={page === 'about' ? "nav-item active" : "nav-item"}>About us</li></Link>
            <Link className="text-uppercase" style={{textDecoration: "none"}} to="/products"><li className={page === 'products' ? "nav-item active" : "nav-item"}>Products</li></Link>
            <Link className="text-uppercase" style={{textDecoration: "none"}} to="/services"><li className={page === 'services' ? "nav-item active" : "nav-item"}>Services</li></Link>
            <Link className="text-uppercase" style={{textDecoration: "none"}} to="/contact"><li className={page === 'contact' ? "nav-item active" : "nav-item"}>Contact us</li></Link>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Navbar
