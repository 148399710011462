import React from 'react'
import { Link } from 'react-router-dom'
const ProductCard = ({ name, desc, img, id }) => {
    return (
        <>
            <div className={id % 2 === 1 ? "col-12 col-lg-6 mt-3 mb-3" : "col-12 offset-lg-1 col-lg-6 mt-3 mb-3"}>
                <Link to={`/products/${name.split(' ')[0]}`}><img style={{cursor: "pointer"}} className="product-image p-1 card-img-top" src={img} alt="product" /></Link>
            </div>
            <div className="col-12 col-lg-4 mt-5">
                <Link to={`/products/${name.split(' ')[0]}`}><h2 style={{cursor: "pointer"}} className="color-orange mt-3">{name}</h2></Link>
                <Link to={`/products/${name.split(' ')[0]}`}><p className="card-text color-white px-2 pt-1 pb-3">{desc}</p></Link>
                <p style={{cursor: "pointer"}} className="color-white go-home ms-2">More Details <i className="fas fa-angle-right color-white"></i> </p>
            </div>
        </>
    )
}

export default ProductCard