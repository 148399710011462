import React from 'react'
import { Helmet } from 'react-helmet'
import PrimarySearchAppBar from '../layouts/Navbar'

const AboutScreen = () => {
    return (
        <>
            <PrimarySearchAppBar page="about"/>
            <div className="container container-catalogue">
                <Helmet>
                    <title>Brightech | About</title>
                </Helmet>
                <div className="container mt-5">
                    <div className="col-12 col-lg-8 mt-5 offset-lg-2">
                        <p className="lead color-white mt-5 text-justify">
                        <span style={{fontSize: "1.5em"}}>Brightech Industries</span> is established Industry formed to meet the growing demand and ongoing need for professional-grade mineral powder with the best quality to be served. <br/><br/> We have our manufacturing unit in Kanpur (U.P.) as well as in Sonepat (Haryana).
                        </p>
                    </div>
                    <div className="row center text-justify mt-5">
                        <div className="home__card col-12 offset-lg-2 col-lg-3 me-3 p-5 center">
                            <img src="https://img.icons8.com/pastel-glyph/64/ffffff/mars-mission.png" alt="Mission"/>
                            <h3 className="sub-heading">
                                MISSION
                            </h3>
                            <p className="content__home text-justify color-white">
                                Our main focus is offering consistent quality to our clients & advantage of its services. Every organization needs growth but growth is the outcome of actions taken in the right direction, at the right time. 
                                Our motive does not only include to make money out of the business but also to offer quality at the convenience of the customers.
                            </p>
                        </div>
                        <div className="home__card offset-lg-1 col-12 col-lg-3 ms-3 center p-5">
                            <img src="https://img.icons8.com/material-outlined/64/ffffff/vision.png" alt="Values"/>
                            <h3 className="sub-heading">
                                VISION
                            </h3>
                            <p className="content__home text-justify color-white">
                                Brightech Industries has been the example of great quality. Quality in terms of providing best material as well as quality in having best employes.
                                Any client should feel to be at the right place for dealing and should not feel to be betrayed and should derive maximum value from the money it is willing to spend. 
                            </p>
                        </div>
                    </div>
                    <p style={{maxWidth: '1250px', marginBottom: '-35px'}} className="col-12 col-lg-8 offset-lg-2 color-white mx-auto p-4 mt-5 text-justify d-none d-lg-block">
                        The focus of  Brightech Industries is to provide high-quality mineral powder for various industrial use across the globe. <br /><br/>
                        We produce finest and purest form of mineral powder like snow white levigated clay, china clay, kaolin clay, talcum, dolomite, calcite, ppt silica powder, granule silica, calcium, natural yellow oxide, natural red oxide, synthetic yellow oxide, synthetic red oxide, carbon, starch, MEG & DEG, diaspore etc. which find its usage in different industries such as Paints, Cosmetics, Rubber, Pesticides & Insecticides, PVC, Pharmaceutial, Detergent Powder & Soap, Ceramics, Paper Coating, Fire Bricks manufacturing. <br /><br/>
                        Quality of our products is defined as it is perceived by our customers. The major factor of our company is our professional attitude with respect to quality and our market driven approach. <br/><br/>
                        We have well defined Quality Assurance Systems, whereas all the employees of Brightech Industries are responsible for quality, and by quality we means both the product as well the services. Our Research & Development (R&D) Department is equipped with the most sophisticated testing labs and the best equipments which carries out the intensive research for the betterment of material. <br /><br/>
                        We have an extensive marketing network with branch offices at Rohini (New Delhi) &amp; Kundli (Sonepat). <br/><br/>
                    </p>
                </div>
            </div>
        </>
    )
}

export default AboutScreen
