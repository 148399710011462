import React from 'react'
import { details } from '../db'
import Logo from '../assets/logoFooter.webp'
// import { Typography } from '@material-ui/core'

const Footer = () => {
    return (
        <div className="footer-dark">
        <footer>
            <div style={{padding: '0 10px'}} className="container mt-3">
                <div className="row">
                    <div className="col-12 col-md-2 align-middle offset-md-1 col-lg-2 item d-none d-md-block">
                       <a href="/"><img src={Logo} width="100%" alt="Logo"/></a>
                    </div>
                    <div className="col-12 col-md-4 offset-lg-1 col-lg-2 align-middle item">
                        <h3>Quick Links</h3>
                        <ul>
                            <li><a href="/about"><i style={{marginRight: '5px'}} className="fas fa-angle-right"></i> About</a></li>
                            <li><a href="/products"><i style={{marginRight: '5px'}} className="fas fa-angle-right"></i> Products</a></li>
                            <li><a href="/services"><i style={{marginRight: '5px'}} className="fas fa-angle-right"></i> Services</a></li>
                            <li><a href="/contact"><i style={{marginRight: '5px'}} className="fas fa-angle-right"></i> Contact</a></li>
                        </ul>
                    </div>
                    <div className="col-12 col-lg-2 ms-3 item align-middle text">
                        <h3>{details.title}</h3>
                            <span><i style={{position: 'absolute', marginTop: '3px', opacity: '0.7'}} className="fas fa-map-marker-alt"></i></span><p style={{display: 'inline-block', marginLeft: '25px'}}>Rania Industrial Area, <br/> Kanpur (Dehat), <br/> Uttar Pradesh (209304)</p>
                    </div>
                        <div className="col-12 col-md-4 ms-5
                     col-lg-3 align-start item">
                        <h3>Contact us</h3>
                        <ul>
                            <li><a href="mailto:kaustubh@gmail.com"><i style={{marginRight: '5px'}} className="fa fa-envelope"></i> info@brightechindustries.com</a></li>
                            <li><a href="tel:91-8375041191"><i style={{marginRight: '5px'}} className="fa fa-mobile-phone"></i> +91-8375041191</a></li>
                        </ul>
                    </div>
                    <div className="col item social mt-5"><a href="facebook.com"><i className="fab fa-facebook-f"></i></a><a href="whatsapp.com"><i className="fab fa-whatsapp"></i></a></div>
                </div>
                <p className="copyright">{details.title} © 2021</p>
            </div>
        </footer>
    </div>
    )
}

export default Footer
