import React from 'react'
import { Helmet }from 'react-helmet'
import Slider from '../layouts/Slider';
import {products} from '../db'
import Catalogue from '../layouts/catalogue';
import PrimarySearchAppBar from '../layouts/Navbar'

const HomeScreen = () => {
    const redirectFun = () => {
        window.location.href = "/products"
    }
    return (
        <>
        <PrimarySearchAppBar page="home"/>
        <Helmet>
            <title>Brightech Industries</title>
        </Helmet>
        <Slider />
        <div className="container-fluid container-catalogue">
                <div className="container">   
                    <p className="lead color-white mx-auto col-12 col-lg-8 text-justify offset-lg-1">
                        <span style={{fontSize: "1.5em"}}>Brightech Industries</span> is formed to meet the growing demand and ongoing need for professional-grade mineral powder across the globe.  
                    </p>
                    <p className="text-justify color-white mx-auto col-12 col-lg-6 mb-5 offset-lg-3">Our company provides the best quality mineral powder for various industrial applications. Brightech Industries today manufactures various grades of mineral powder which find its usage in different industries such as Paints, Cosmetics, Rubber, Pesticides & Insecticides, PVC, Pharmaceutial, Detergent Powder & Soap, Ceramics, Paper Coating, Fire Bricks etc.
                       <br/><br/> We have an extensive marketing network with branch offices at Rohini (New Delhi) & Kundli (Sonepat).
                    </p>
                    <h1 className="text-reveal color-white text-justify mt-5 mb-4 center">
                        Our Products
                    </h1>
                <div className="row row-cols-1 row-cols-lg-3 g-2">
                    {
                        products.map(i => {
                            return (
                                <Catalogue key={i._id} id={i._id} name={i.name} price={i.price} desc={i.desc} img={i.img}/>
                            )
                            }
                        )    
                    }
                </div>
            </div> 
            <div class="d-grid gap-2 col-6 col-lg-4 col-lg-3 mx-auto">
                <button onClick={redirectFun} class="btn btn-orange mt-5" type="button">See More</button>
            </div>
        </div>      
        </>
    )
}

export default HomeScreen
